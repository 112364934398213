import Image from "next/image"; // CUSTOM COMPONENTS

import { H5 } from "components/Typography";
import FlexRowCenter from "components/flex-box/flex-row-center"; // IMPORT IMAGES

// import logo from "/boh-logo-flame-large.svg";
export default function LogoWithTitle() {

  return <FlexRowCenter flexDirection="column" gap={1.5} mb={4}>
      <Image src='boh-logo-flame-large.svg' alt="BOH Supply logo" height="100"
              width="200"/>
      <H5 fontWeight={700}></H5>
    </FlexRowCenter>;
}