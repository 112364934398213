import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; // ==============================================================
import { useCartActions } from "hooks/useCartActions";
// ==============================================================
export default function BottomActions({
  total,
  handleNavigate,
  userID
}) {
  
  const {getCartKeyUsedForCheckout} = useCartActions();
  const BASE_URL = process.env.NEXT_PUBLIC_BASEURL;


  //Sends to LOGIN if not logged in and to checkout if they are
  // let url = `${BASE_URL}my-account/?cart_key=${localStorage.getItem("woo_cart_key")}`
  let url = `${BASE_URL}my-account}`

  return <Box p={2.5}>
     <Button
  fullWidth
  color="primary"
  variant="contained"
  sx={{
    mb: "0.75rem",
    height: "40px"
  }}
  // onClick={() => handleNavigate(user ? url : guestUrl)}
  onClick={() => handleNavigate(url)}

>
  Checkout Now ({total})
</Button>


      {/* <Button fullWidth color="primary" variant="outlined" sx={{
      height: 40
    }} onClick={handleNavigate("/cart")}>
        View Cart
      </Button> */}
    </Box>;
}