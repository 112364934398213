
import { useRouter } from "next/navigation";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useCart from "hooks/useCart";
import { useCartActions } from "hooks/useCartActions";
import TopHeader from "./components/top-header";
import MiniCartItem from "./components/cart-item";
import EmptyCartView from "./components/empty-view";
import BottomActions from "./components/bottom-actions";
import Scrollbar from "components/scrollbar";
import { currency } from "lib";

// =========================================================
export default function MiniCart({ toggleSidenav }) {
  const { push } = useRouter();
  const { cart, userID } = useCart();
  const { removeFromCart, changeCartAmount } = useCartActions();

console.log('in mini cart', userID)
  const handleChangeCartAmount = (amount, product) => {
    if (amount > 0) {
      return changeCartAmount(product, amount);
    } else {
      return removeFromCart(product.id);
    }
  };
  

  const getTotalPrice = () => {
    return cart.reduce((acc, item) => acc + item.price * item.qty, 0);
  };

  const getTotalItems = () => {
    return cart.reduce((acc, item) => acc + item.qty, 0);
  };

  const handleNavigate = (path)  => {
    // if no userID - send to auth?
    if (!userID) push('/profile');
    else push(path);
    toggleSidenav();
  
  };

  return (
    <Box width="100%" minWidth={380}>
      <TopHeader toggle={toggleSidenav} total={getTotalItems()} />
      <Divider />
      <Box height={`calc(100vh - ${cart.length ? "150px" : "75px"})`}>
        {cart.length > 0 ? (
          <Scrollbar>
            {cart.map((item, index) => (
              <MiniCartItem
                item={item}
                key={`${item.item_key}${index}`} 
                handleChangeCartAmount={handleChangeCartAmount}
              />
            ))}
          </Scrollbar>
        ) : (
          <EmptyCartView />
        )}
      </Box>
      {cart.length > 0 && (
        <BottomActions
          userID={userID}
          total={currency(getTotalPrice())}
          handleNavigate={handleNavigate}
        />
      )}
    </Box>
  );
}
