
import Link from "next/link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Remove";
import { FlexBox } from "components/flex-box";
import { H6, Tiny } from "components/Typography";
import { currency } from "lib";
import { useState } from "react";
import { CircularProgress, Snackbar, Alert } from "@mui/material";


export default function MiniCartItem({ item, handleChangeCartAmount }) {
  const [qty, setQty] = useState(item.qty);
  const [loading, setLoading] = useState(false);
  const [deletionStatus, setDeletionStatus] = useState("");
  const [fadeEffect, setFadeEffect] = useState(false);


  const handleDeleteClick = () => {
    setFadeEffect(true);
    setLoading(true);
    handleChangeCartAmount(0, item)
      .then(() => {
        setDeletionStatus("success");
      })
      .catch(() => {
        setDeletionStatus("fail");
      })
      .finally(() => {
        setLoading(false);
        setFadeEffect(false);
      });
  };
 
  const handleCloseSnackbar = () => {
    setDeletionStatus("");
  };

  return (
    <FlexBox
      py={2}
      px={2.5}
      key={item.id}
      alignItems="center"
      borderBottom="1px solid"
      borderColor="divider"
      sx={{
        opacity: fadeEffect ? 0.5 : 1,
        transition: "opacity 0.5s ease-in-out"
      }}
    >
      {loading && (
        <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
      )}
         <FlexBox alignItems="center" flexDirection="column">
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            setQty(qty + 1);
            handleChangeCartAmount(qty + 1, item);
          }}
          sx={{
            height: 28,
            width: 28,
            borderRadius: 50,
          }}
        >
          <Add fontSize="small" />
        </Button>

        <H6 my="3px">{qty}</H6>

        <Button
          size="small"
          color="primary"
          variant="outlined"
          disabled={item.qty === 1}
          onClick={() => {
            setQty(qty - 1);
            handleChangeCartAmount(qty - 1, item);
          }}
          sx={{
            height: 28,
            width: 28,
            borderRadius: 50,
          }}
        >
          <Remove fontSize="small" />
        </Button>
      </FlexBox>

      <Link href={`/products/${item.slug}`}>
        <Avatar
          alt={item.name}
          src={item.imgUrl}
          sx={{
            mx: 1,
            width: 75,
            height: 75,
          }}
        />

        {/* <Image
          src={item.imgUrl}
          alt={item.name}
          height="75"
          width="75"
          style={{margin: '8px', 
        }}
        /> */}
      </Link>

      <Box
        flex="1"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        <Link href={`/products/${item.slug}`}>
          <H6 ellipsis className="title">
            {item.name}
          </H6>
        </Link>

        <Tiny color="grey.600">
          {currency(item.price)} x {item.qty}
        </Tiny>

        <H6 color="primary.main" mt={0.5}>
          {currency(qty * item.price)}
        </H6>
      </Box>
      <IconButton
        size="small"
        onClick={handleDeleteClick}
        sx={{
          marginLeft: 2.5,
        }}
      >
        <Close fontSize="small" />
      </IconButton>

      <Snackbar open={deletionStatus === "success"} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          Item successfully deleted!
        </Alert>
      </Snackbar>

      <Snackbar open={deletionStatus === "fail"} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          Failed to delete item.
        </Alert>
      </Snackbar>
    </FlexBox>
  );
}
